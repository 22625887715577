.login-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;

    .login-head-title {
        height: 75px;
        background-color: #fff;
        padding-left: 197px;
        // padding-top: 19px;
        padding-bottom: 19px;

        h2 {
            font-size: 24px;
            line-height: 31.68px;
            color: #0070CC;
        }
    }

    .login-footer {
        text-align: center;
        color: #999090;
        background-color: #F1F1F1;

        span {
            padding: 0 16px;
        }
    }

    .login-content {
        flex-grow: 1;
        overflow: hidden;
        background-color: #F1F1F1;

        .login-left-container {
            text-align: center;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;

            .login-left-img {
                width: 534px;
                height: 513px;
                position: relative;
                margin: 0 auto;
                top: 20%;
            }
        }

        .login-right-container {
            text-align: center;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;

            .login-box {
                width: 464px;
                height: 344px;
                position: relative;
                margin: 0 auto;
                top: 20%;
                background-color: #fff;


                .login-type {
                    margin-bottom: 24px;
                    border-bottom: 1px solid #Eee;
                    height: 60px;
                    line-height: 60px;

                    .login-tab-active:after {
                        content: '';
                        position: absolute;
                        left: 30%;
                        top: auto;
                        bottom: 0;
                        right: auto;
                        height: 3px;
                        width: 40%;
                        background-color: #00000080;
                    }

                }

                // .login-type > Col {color:red;}

                .login-registered {
                    float: right;
                }

                .login-the-type {
                    font-size: 20px;
                    color: #5782ff;
                    font-weight: bold;
                }

                .login-get-code {
                    position: absolute;
                    right: 0px;
                    top: 12px;
                    cursor: pointer;
                    z-index: 9999;

                    a {
                        padding: 0 24px 0 16px;
                    }
                }
            }

        }

    }

    // .login {
    //     overflow: hidden;
    //     background-color: #F1F1F1;

    //     .right-ct {
    //         height: 100vh;
    //         width: 100%;
    //     }

    //     .left-ct {
    //         display: flex;
    //         justify-content: center;

    //         .login-left {
    //             width: 56%;
    //             margin-top: 100px;

    //             h2 {
    //                 color: #5782ff;
    //                 font-size: 38px;
    //                 font-weight: bold;
    //                 margin-bottom: 42px;
    //             }



    //             }




    //         }

    //         .ant-input-affix-wrapper {
    //             border: 0;
    //             border-bottom: 1px solid #d9d9d9;
    //             padding: 12px 0;
    //         }

    //         .ant-input-prefix {
    //             margin-right: 12px;
    //         }


    //     }


    // }
}