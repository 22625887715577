.log-content {
    width: 100%;
    height: 400px;
    background-color: rgb(85,85,85);
    color: #fff;
    margin-top: 20px;
    overflow: auto;
    text-align: left;
    p {
        margin: 0;
    }
}