.task-log-content {
  width: 100%;
  height: 600px;
  background-color: rgb(85, 85, 85);
  color: #fff;
  overflow: auto;
  text-align: left;

  p {
    margin: 0;
  }
}

.task-log-container {
  .ant-modal-body {
    padding: 28px 17px 17px 20px;
  }
}

.ant-pagination-item-active {
  border-color: #0070CC;
}
.ant-pagination {
  .ant-pagination-options:hover {
    border-color: #0070CC;
  }
}