// 自适应
// //使用scss的math函数
// @use 'sass:math';

// // 默认设计稿的宽度
// $designWidth: 1920;
// // 默认设计稿的高度
// $designHeight: 1080;

// // px转vw的函数
// @function vw($px) {
//     // 类似除法运算符（$px/$designWidth） * 100vw
//     @return $px/$designWidth * 100vw;
// }

// // px转vh的函数
// @function vh($px) {
//     @return $px/$designHeight*100vh;
// }

.node-mm .ant-form-item-label {
    width: 62px;
}

.ant-advanced-search-assign .ant-form-inline .ant-form-item-with-help {
    margin-bottom: 0px;
}