.yunxing {
    .common-green {
        margin: 10px;
    }
    
}
.can-update {
    color: #FF4D4F;
    margin-left: 20px;
}
.node-update {
    margin-left: 20px;
    color: #0070CC;
    border-color: #0070CC;
    height: 100%;
}

.update-content {
    background-color: #fff;
    .update-list {
        padding: 16px;
        border-bottom: 1px solid #0000001F;
    }
    .update-table {
        padding: 20px;
    }
}
.clearfix:after{
    content: "";
    display: block;
    height: 0;
    clear:both;
    visibility: hidden;
}