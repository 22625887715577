.ota-bottom-card {
    margin-top: 12px;
    background-color: #fff;

    .ota-bottom-card-title {
      width: 100%;
      height: 36px;
      color: #5C6173;
      padding-top: 7px;
      padding-left: 16px;
      padding-bottom: 5px;
      border-bottom: 1px solid #0000001F;
    }

    .ota-bottom-content {
      padding: 17px 20px 20px 20px;

      .common-search-form {
        padding-bottom: 13px;
        border-bottom: 1px solid #0000001F;
        margin-bottom: 10px;

        .ant-form-item {
          margin-bottom: 0px;
        }

        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
          height: 28px;
        }

        .ant-form-item .ant-select,
        .ant-form-item .ant-cascader-picker {
          width: 200px;
        }
      }
    }
}
.ant-table-thead>tr>th {
    background-color: #EDEDED;
    height: 36px;
    font-size: 12px;
    padding: 5px 16px;
  }
  
  .ant-table-container {
    border: 1px solid #EDEDED;
    border-radius: 8px;
  }