.market-search-form {
    height: 28px;
    margin-bottom: 12px;

    .ant-btn-primary {
        background: #0070CC;
        border-color: #0070CC;
    }

    .base-btn-con {
        float: right;

        .base-btn {
            width: 88px;
            height: 28px;
        }

        .ant-btn-primary {
            background: #FF4D4F;
            border-color: #FF4D4F;
        }
    }


    .ant-input-affix-wrapper {
        height: 28px;
    }

    .ant-input-search-button {
        height: 28px;
        width: 60px;
    }
}

.market-type {
    // display: flex;
    // align-items: center;
    height: 66px;
    width: 100%;
    margin-bottom: 12px;
    background-color: #fff;
    padding: 20px 16px;
    display: flex;

    .market-type-title {
        width: 42px;
        line-height: 33px;
    }

    .market-type-container {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        margin-left: 3px;

        // justify-content: space-between;
        .primary-btn {
            width: 78px;
            height: 34px;
            border: 1px solid #DDDDDD;
            background: #F7F7F7;
            text-align: center;
            box-sizing: border-box;
            border-radius: 2px;
            line-height: 34px;
            list-style-type: none;
            cursor: pointer;
            margin-right: 10px;
        }

        .select-btn {
            background-color: #0070CC;
            color: #fff;
        }
    }
}

.market-card-container {

    // display: flex;


    .market-card {
        width: 32.5%;
        height: 250px;
        padding: 22px;
        margin-bottom: 12px;
        margin-right: 8px;
        background-color: #fff;
        float: left;

        .market-card-top {
            display: flex;

            img {
                width: 50px;
                height: 50px;
            }

            div {
                flex-grow: 1;
                margin-left: 12px;

                .market-card-top-title {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: left;
                    cursor: pointer;
                    color: #0070CC;
                }

                .market-card-top-type {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #5C6173;
                }

                .market-card-top-desc {
                    font-size: 12px;
                    line-height: 20px;
                    letter-spacing: 0em;
                    text-align: justified;
                    color: #BFBFBF;
                    height: 80px;
                }

            }
        }

        .market-card-bottom {
            display: flex;

            .market-card-version {
                flex-grow: 1;
                color: #979797;
                font-size: 12px;
                font-weight: 400;
                line-height: 22px;
                text-align: left;
            }

            .market-card-btn {
                width: 64px;
                height: 28px;
                margin-top: 9px;
            }
        }
    }

}

.market-card .market-card-bottom .ant-btn:hover,
.market-card .market-card-bottom .ant-btn:focus,
.market-card .market-card-bottom .ant-btn:active {
    background: #0070CC;
}

.market-card .market-card-bottom .ant-btn:hover,
.market-card .market-card-bottom .ant-btn:focus {
    color: #fff;
}

.market-card .market-card-bottom .ant-btn {
    color: #0070CC;
    border-color: #0070CC;
}