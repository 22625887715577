.monitor-search {
    height: 60px;
    padding: 16px;
    width: 100%;
    background-color: #fff;
}
.monitoring-echarts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    .ant-card {
        width: 49.6%; 
         
        #main {
            width: 100%;
            height: 360px;
        }
    } 
    .mt12 {
        margin-top: 12px;
    }
    .ant-card-body {
        padding: 14px;
    }
    .ant-divider-horizontal {
        width: auto;
        margin: 14px;
        margin-left: -14px;
        margin-right: -14px;
    }
}