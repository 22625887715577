.first-page {
    h3 {
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
        margin-right: 32px;
        font-weight: bold;
        nz-select {
            width: 55%;
            font-weight: normal;
        }
    }
    h3.title {
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        padding-bottom: 20px;
        margin-bottom: 40px;
    }
    .number {
        display: flex;
        flex-wrap: wrap;
        dl {
           
            padding: 8px 12px 0 12px;
            text-align: center;
            flex: 1;
            min-width: 20%;
            dt {
                font-size: 32px; 
                margin-bottom: 24px;
                img {
                    width: 50%;
                }
            }
            dd {
                p {
                    line-height: 16px;
                    span {
                        font-size: 32px;
                        font-weight: bold;
                    }
                }
                p:nth-child(1) {
                    font-size: 24px;
                    margin-bottom: 16px;
                }
                p.name {
                    margin-bottom: 0;
                    color: rgba(0, 0, 0, 0.85);
                    color: #888888;
                }
               
            }
        }
    }
    .first-echarts {
        display: flex;
        width: 100%;
        .ant-card {
            width: 50%;
            margin-top: 18px;
            margin-right: 18px;
            #main {
                width: 100%;
                height: 320px;
            }
        }
    }
    .space{
        width:18px;
    }
    .first-echarts2 {
        display: flex;
        width: 100%;
        
        .ant-card {
            width: 50%;
            margin-top: 18px;
            #main {
                width: 100%;
                height: 320px;
            }
        }
    }
   
}

