.qx-oper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
    span.qx-label {
        display: inline-block;
        width: 180px;
        padding: 3px 5px;
        border: 1px solid #ddd;
        background-color: #f5f5f5;
        border-radius: 3px;
    }
}

 
.lr-divider {
    width: auto;
    margin-left: -60px !important;
    margin-right: -60px !important;
}
.common-detail-container .common-edit-card {
    padding: 40px 60px;
}
