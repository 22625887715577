.regist-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;

    .regist-head-title {
        height: 64px;
        background-color: #fff;
        padding-left: 224px;
        padding-top: 13px;
        padding-bottom: 13px;

        h2 {
            font-size: 24px;
            line-height: 31.68px;
            color: #0070CC;
        }
    }

    .regist-footer {
        text-align: center;
        color: #999090;
        background-color: #F1F1F1;

        span {
            padding: 0 16px;
        }
    }

    .regist-content {
        flex-grow: 1;
        overflow: hidden;
        background-color: #F1F1F1;

        .regist-right-container {
            text-align: center;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;

            .regist-box {
                width: 992px;
                height: 607px;
                position: relative;
                margin: 0 auto;
                top: 20%;
                background-color: #fff;

                .regist-card-title {
                    width: 100%;
                    height: 87px;
                    border-bottom: 1px solid #eee;
                    padding: 32px;
                    color: rgba(0, 0, 0, 0.85);
                    line-height: 24px;
                }

                .regist-registered {
                    float: right;
                }

                .regist-input {
                    width: 432px;
                    height: 42px;
                }
            }
        }
    }
}

.fs20 {
    font-size: 20px;
}
.fs14 {
    font-size: 14px;
}
// .registered {
//     overflow: hidden;
//     .right-ct {
//         height: 100vh; 
//         width: 100%;
//         img {
//             height: 100%;
//             width: 100%;
//         }
//     }
//     .left-ct {
//         display: flex;
//         justify-content: center;
//         .login-left {
//             width: 56%;
//             margin-top: 42px;
//             h2 {
//                 color: #5782ff;
//                 font-size: 38px;
//                 font-weight: bold;
//                 margin-bottom: 42px;
//             }
//             .login-type {
//                 margin-bottom: 24px;
//                 font-size: 20px;
//                 font-weight: bold;
//             }
//              .login-btn {
//                  margin-top: 30px;
//              }
//             .registered {
//                 text-align: center;
//             }
//         }
//         .ant-input-affix-wrapper {
//             border: 0;
//             border-bottom: 1px solid #d9d9d9;
//             padding: 12px 0;
//         }
//         .ant-input-prefix {
//             margin-right: 12px;
//         }
//         .ant-form-item {
//             margin-bottom: 12px;
//         }
//         .get-code {
//             position: absolute;
//             right: 0px;
//             top: 12px;
//             a {
//                 padding: 0 24px 0 16px;
//             }
//         }
//     }

//     .registered-footer {
//         text-align: center;
//         padding-top: 48px;
//         color: #999090;
//         span {
//             padding: 0 16px;
//         }
//     }
// }