// .user-table-card {
//     margin-top: 17px;
//     .ant-table-thead>tr>th {
//         background-color: #EDEDED;
//         height: 36px;
//         font-size: 12px;
//     }

//     .ant-table-container {
//         border: 1px solid #EDEDED;
//         border-radius: 8px;
//     }
//     .ant-checkbox-wrapper-disabled {
//         display: none;
//     }
// }


.log-mes-roll {
    font-family: Microsoft YaHei;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #5C6173;

    span {
        color: rgba(0, 0, 0, 0.85);
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        margin-right: 40px;
    }
}

.ml34 {
    margin-left: 34px;
}
.ml15 {
    margin-left: 15px;
}
.mt32 {
    margin-top: 32px;
}

.log-search-form {
    padding-bottom: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 10px;

    .ant-form-item {
      margin-bottom: 0px;
    }

    .ant-input {
      width: 200px;
      height: 28px;
    }

    .ant-form-item-label>label {
      font-size: 12px;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 28px;
    }

    .ant-form-item .ant-select,
    .ant-form-item .ant-cascader-picker {
      width: 200px;
    }
  }