.user-table-card {
    margin-top: 17px;
    .ant-table-thead>tr>th {
        background-color: #EDEDED;
        height: 36px;
        font-size: 12px;
    }

    .ant-table-container {
        border: 1px solid #EDEDED;
        border-radius: 8px;
    }
    .ant-checkbox-wrapper-disabled {
        display: none;
    }
}
.ant-select-arrow {
    width: 0;
    height: 0;
    margin-top: -4px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid rgba(0, 0, 0, 0.5);
}
.ant-select-arrow .anticon {
    display: none;
}
.common-top-title0 {
    font-weight: bolder;
}
