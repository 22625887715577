.ant-modal-header {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 12px;
    height: 42px;

    .ant-modal-title {
        font-size: 14px;
        line-height: 14px;
        color: #000000D9;
    }

    .ant-modal-body {
        padding: 70px 40px;
    }

    input {
        width: 200px;
        height: 28px;
    }
}