.user-center {
    .ant-menu-inline {
        height: 79vh;
    }
    .ant-card {
        padding: 0 24px;
        .ant-form-item-control-input-content {
            display: flex;
            a {
                width: 60px;
                line-height: 32px;
                padding-left: 16px;
            }
        }
    }
}