.logo {
  height: 46px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 24px;
  line-height: 23px;
  float: left;
  padding-left: 12px;

  width: 200px;
}
.site-layout .site-layout-background {
  background-color: #282B33;
}
.ant-layout-header {
  height: 56px;
  background: #005BBB;
}
.ant-layout-sider {
  background: #ffffff;
}
#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 24px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger svg {
  font-size: 20px;
  margin-left: 18px;
  margin-bottom: 13px;
  margin-top: 18px;
  color: rgba(255, 255, 255, 0.85);
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #5782ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

/* .ant-layout.ant-layout-has-sider {
    height: 100vh;
  } */
.help {
  position: absolute;

  right: 16px;
  top: -4px;

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 12px;
  }
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  border-left: 3px solid #5782ff;
  background: linear-gradient(to right, #142661, #1a182e 70%);
}

.site-layout .ant-layout-content {
  background: none;
  padding: 0;
}

.ant-layout.ant-layout-has-sider {
  min-height: 100vh;
}

.site-layout .ant-layout-sider-collapsed {
    flex: 0 0 0px !important;
    max-width: 0px !important;
    min-width: 0px !important;
    width: 0px !important;
    overflow: hidden;
}

.site-layout {
    .ant-menu-inline .ant-menu-item::after {
        transform: scaleY(1);
        opacity: 0;
        border-color:  #C4C4C4;
    }
    .ant-menu-inline .ant-menu-item-selected::after {
        border-color:  #1890ff;
        opacity: 0;
    }
   .ant-menu-submenu-open .ant-menu-item a {
        padding-left: 10px;
        border-left: 3px solid #C4C4C4;
    }
    .ant-menu-submenu-open .ant-menu-item-selected a {
        border-color:  #1890ff;
    }
}