.role-menu-item {
    width: 225px;
    height: 28px;
    border: 1px solid #D9D9D9;
    background: #F9F9F9;
    box-sizing: border-box;
    border-radius: 4px;

    font-size: 12px;
    line-height: 22px;
    color: #00000073;
    padding-left: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    float: left;
    margin-right: 70px;
}