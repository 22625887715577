body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.common-pd20 {
  padding: 16px 20px;
}

.common-top-title0 {
  font-weight: bold;
  font-size: 16px;
  // margin-bottom: 16px;
}

.common-center-container {
  padding: 16px 20px 20px 20px;
  background-color: #fff;
}

.top-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  div {
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;

    img {
      width: 20px;
      margin-right: 6px;
      cursor: pointer;
    }

    img:hover {
      opacity: .8;
    }
  }

  .ant-breadcrumb {
    font-size: 12px;
    font-weight: normal;
  }
}

.page-block {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
}

.error404 {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: currentColor;

  div {
    width: 474px;
    text-align: center;

    button {
      margin-top: 36px;
    }
  }
}

.job-box-star {
  .ant-form-item-label>label::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 13px;
    line-height: 1;
    content: '*' !important;
  }

  // .ant-form-item-control-input-content::after {
    // display: block;
    // margin-top: 4px;
  //   color: #ff4d4f;
  //   font-size: 14px;
  //   font-family: SimSun, sans-serif;
  //   line-height: 1;
  //   content: '请选择epack版本';
  // }
}


.common-star-red {
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
}

.common-green {
  width: 6px;
  height: 6px;
  background: #73D13D;
  float: left;
  margin: 6px;
  border-radius: 3px;
}

.common-red {
  width: 6px;
  height: 6px;
  background: #FF4D4F;
  float: left;
  margin: 6px;
  border-radius: 3px;
}

.common-blue {
  width: 6px;
  height: 6px;
  background: #0070CC;
  float: left;
  margin: 6px;
  border-radius: 3px;
}

.common-gray {
  width: 6px;
  height: 6px;
  background: rgba(0, 0, 0, 0.2);
  float: left;
  margin: 6px;
  border-radius: 3px;
}

.common-yellow {
  width: 6px;
  height: 6px;
  background: #FFA940;
  float: left;
  margin: 6px;
  border-radius: 3px;
}

.common-purple {
  width: 6px;
  height: 6px;
  background: #BB6BD9;
  float: left;
  margin: 6px;
  border-radius: 3px;
}

.common-deep-gray {
  width: 6px;
  height: 6px;
  background: #777777;
  float: left;
  margin: 6px;
  border-radius: 3px;
}

/* table中状态的样式 */
.ant-table-content {
  .common-green,
  .common-red,
  .common-blue,
  .common-gray,
  .common-yellow,
  .common-purple,
  .common-deep-gray {
    margin-left: -16px;
  }
}

// 列表页

.common-center-container {

  // search
  .common-search-form {
    padding-bottom: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 16px;

    // .ant-form {
    //   // height: 42px;
    //   // border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    //   // margin-bottom: 20px;
    // }

    .ant-form-item {
      margin-bottom: 0px;
    }

    .ant-input {
      width: 200px;
      height: 28px;
    }

    .ant-form-item-label>label {
      font-size: 12px;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 28px;
    }

    .ant-form-item .ant-select,
    .ant-form-item .ant-cascader-picker {
      width: 200px;
    }
  }

  // table
  .ant-checkbox-inner {
    width: 12px;
    height: 12px;
    border-color: #5C6173;
  }

  .ant-table-tbody>tr>td {
    height: 44px;
    font-size: 12px;
  }

  .ant-table-thead>tr>th,
  .ant-table-tbody>tr>td,
  .ant-table tfoot>tr>th,
  .ant-table tfoot>tr>td {
    padding: 6px 16px;
  }

  // page
  .common-bottom-pagination {
    margin-top: 12px;

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      min-width: 28px;
      height: 28px;
      line-height: 1px;
    }

    .ant-pagination-item {
      min-width: 28px;
      height: 28px;
      line-height: 25px;
      font-size: 12px;
    }
  }

  .ant-btn-primary {
    background: #0070CC;
    border-color: #0070CC;
    color: rgba(255, 255, 255, 0.85) !important;
  }

  .ant-btn {
    width: 64px;
    height: 28px;
    line-height: 1px;
    font-size: 12px;
    color: #5C6173;
    border-radius: 2px;
  }
}

// 新增页
.common-create-center-container {
  padding: 40px 60px;
  background: #fff;
  margin-bottom: 100px;

  .common-create-page {
    .ant-form-item-label>label {
      font-size: 12px;
    }

    input.ant-input {
      height: 28px;
      width: 300px;
    }

    .ant-input-affix-wrapper {
      height: 28px;
      width: 300px;

      .ant-input {
        height: 22px;
      }
    }
  }
}

// 底部按钮组
.common-bottom-btns-card {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  height: 80px;
  box-shadow: black;
  width: 100%;
  margin-left: -80px;
  z-index: 9;
  box-shadow: 0px -3px 5px 0px #1b0e0e24;

  .ant-btn-primary {
    background: #0070CC;
    border-color: #0070CC;
    color: rgba(255, 255, 255, 0.85) !important;
  }

  .ant-btn {
    width: 64px;
    height: 28px;
    line-height: 1px;
    font-size: 12px;
    color: #5C6173;
    border-radius: 2px;
  }
}

// 详情页
.common-detail-container {
  margin: 24px 24px 100px 24px;

  // 样式
  .cannot-edit {
    border: 0;
  }

  .common-edit-card {
    background-color: #fff;
    padding: 40px 60px;
  }

  .common-trans-card {
    margin-top: 12px;
    background-color: #fff;
    padding: 20px;

    .ant-btn-primary {
      background: #FF4D4F;
      border-color: #FF4D4F;
      color: rgba(255, 255, 255, 0.85) !important;
    }

    .ant-btn {
      width: 64px;
      height: 28px;
      line-height: 1px;
      font-size: 12px;
      color: #5C6173;
      border-radius: 2px;
    }

    .ant-table-thead>tr>th {
      background-color: #EDEDED;
      border-bottom: 0px;
    }

    // 重写样式
    .ant-form-item-label>label {
      font-size: 12px;
      padding-left: 13px;
    }

    .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      position: absolute;
      left: 0px;
    }

    input.ant-input {
      height: 28px;
      width: 300px;
    }

    .ant-input-affix-wrapper {
      height: 28px;
      width: 300px;

      .ant-input {
        height: 22px;
      }
    }


    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: rgba(92, 97, 115, 1);
    }

    .ant-tabs-tab {
      color: rgba(0, 0, 0, 0.45);
    }

    .ant-tabs-top>.ant-tabs-nav,
    .ant-tabs-bottom>.ant-tabs-nav,
    .ant-tabs-top>div>.ant-tabs-nav,
    .ant-tabs-bottom>div>.ant-tabs-nav {
      margin-bottom: 15px;
    }

    .common-trans-card>div>div.ant-tabs-nav>div.ant-tabs-nav-wrap>div>div.ant-tabs-tab {
      width: 76px;
      text-align: center;

      .ant-tabs-tab-btn {
        width: 100%;
      }

      .ant-tabs-tab+.ant-tabs-tab {
        margin: 0 0 0 18px;
      }
    }

  }

  .common-trans-card p {
    margin: 0;
  }




}

// ota详情页

.ota-detail-container {
  margin: 24px 24px 100px 24px;

  // 样式
  .cannot-edit {
    border: 0;
  }

  .common-edit-card {
    background-color: #fff;
    padding: 40px 60px;
  }

  .ota-bottom-card {
    margin-top: 12px;
    background-color: #fff;

    .ota-bottom-card-title {
      width: 100%;
      height: 36px;
      color: #5C6173;
      padding-top: 7px;
      padding-left: 16px;
      padding-bottom: 5px;
      border-bottom: 1px solid #0000001F;
    }

    .ota-bottom-content {
      padding: 17px 20px 20px 20px;

      .common-search-form {
        padding-bottom: 13px;
        border-bottom: 1px solid #0000001F;
        margin-bottom: 10px;

        .ant-form-item {
          margin-bottom: 0px;
        }

        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
          height: 28px;
        }

        .ant-form-item .ant-select,
        .ant-form-item .ant-cascader-picker {
          width: 200px;
        }
      }
    }

    .ant-btn-primary {
      background: #0070CC;
      border-color: #0070CC;
      color: rgba(255, 255, 255, 0.85) !important;
    }

    .ant-btn {
      width: 88px;
      height: 28px;
      line-height: 1px;
      font-size: 12px;
      color: #5C6173;
      border-radius: 2px;
    }

    .ant-table-container {
      border: 1px solid #ededed;
    }

    .ant-table-thead>tr>th {
      background-color: #EDEDED;
      border-bottom: 0px;
    }

    // 重写样式
    .ant-form-item-label>label {
      font-size: 12px;
      padding-left: 13px;
    }

    .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      position: absolute;
      left: 0px;
    }

    input.ant-input {
      height: 28px;
      width: 300px;
    }

    .ant-input-affix-wrapper {
      height: 28px;
      width: 300px;

      .ant-input {
        height: 22px;
      }
    }


    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: rgba(92, 97, 115, 1);
    }

    .ant-tabs-tab {
      color: rgba(0, 0, 0, 0.45);
    }

    .ant-tabs-top>.ant-tabs-nav,
    .ant-tabs-bottom>.ant-tabs-nav,
    .ant-tabs-top>div>.ant-tabs-nav,
    .ant-tabs-bottom>div>.ant-tabs-nav {
      margin-bottom: 15px;
    }

    .ota-bottom-content>div>div.ant-tabs-nav>div.ant-tabs-nav-wrap>div>div.ant-tabs-tab {
      width: 76px;
      text-align: center;

      .ant-tabs-tab-btn {
        width: 100%;
      }

      .ant-tabs-tab+.ant-tabs-tab {
        margin: 0 0 0 18px;
      }
    }

  }

  .ota-bottom-content p {
    margin: 0;
  }





}



.node-log-container {
  padding: 16px 20px 20px 20px;

  .node-log-search {
    margin-bottom: 16px;

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 28px;
    }

    .ant-form-item-label>label {
      font-size: 12px;
    }

    .ant-btn-primary {
      background: #0070CC;
      border-color: #0070CC;
      color: rgba(255, 255, 255, 0.85) !important;
    }

    .ant-btn {
      width: 64px;
      height: 28px;
      line-height: 1px;
      font-size: 12px;
      color: #5C6173;
      border-radius: 2px;
    }
  }

  .node-log-content {
    background-color: #fff;
    padding: 20px;

    .ant-table-thead>tr>th,
    .ant-table-tbody>tr>td,
    .ant-table tfoot>tr>th,
    .ant-table tfoot>tr>td {
      padding: 6px 16px;
    }

    .ant-table {
      font-size: 12px;
      line-height: 24px;
    }

    .ant-table-thead>tr>th {
      color: #000000A6;
    }
  }

}


// 升级
.job-box {
  padding: 40px 60px;
  background: #fff;

  .ant-btn {
    width: 88px;
    height: 28px;
    line-height: 1px;
    font-size: 12px;
    border-radius: 2px;
    border: 1px solid #0070CC;
    color: #0070CC
  }
}

.job-bottom-card {
  margin-top: 12px;
  margin-bottom: 12px;
  background-color: #fff;

  .job-bottom-card-title {
    width: 100%;
    height: 36px;
    color: #5C6173;
    padding-top: 7px;
    padding-left: 16px;
    padding-bottom: 5px;
    border-bottom: 1px solid #0000001F;
  }

  .job-bottom-content {
    padding: 40px 20px;

    .common-search-form {
      padding-bottom: 13px;
      border-bottom: 1px solid #0000001F;
      margin-bottom: 10px;

      .ant-form-item {
        margin-bottom: 0px;
      }

      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 28px;
      }

      .ant-form-item .ant-select,
      .ant-form-item .ant-cascader-picker {
        width: 200px;
      }
    }
  }

  .ant-btn-primary {
    background: #0070CC;
    border-color: #0070CC;
    color: rgba(255, 255, 255, 0.85) !important;
  }

  .ant-btn {
    width: 88px;
    height: 28px;
    line-height: 1px;
    font-size: 12px;
    color: #5C6173;
    border-radius: 2px;
  }

  .ant-table-container {
    border: 1px solid #ededed;
  }

  .ant-table-thead>tr>th {
    background-color: #EDEDED;
    border-bottom: 0px;
  }

  // 重写样式
  .ant-form-item-label>label {
    font-size: 12px;
    padding-left: 13px;
  }

  .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    left: 0px;
  }

  input.ant-input {
    height: 28px;
    width: 300px;
  }

  .ant-input-affix-wrapper {
    height: 28px;
    width: 300px;

    .ant-input {
      height: 22px;
    }
  }


  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: rgba(92, 97, 115, 1);
  }

  .ant-tabs-tab {
    color: rgba(0, 0, 0, 0.45);
  }

  .ant-tabs-top>.ant-tabs-nav,
  .ant-tabs-bottom>.ant-tabs-nav,
  .ant-tabs-top>div>.ant-tabs-nav,
  .ant-tabs-bottom>div>.ant-tabs-nav {
    margin-bottom: 15px;
  }

  .job-bottom-content>div>div.ant-tabs-nav>div.ant-tabs-nav-wrap>div>div.ant-tabs-tab {
    width: 76px;
    text-align: center;

    .ant-tabs-tab-btn {
      width: 100%;
    }

    .ant-tabs-tab+.ant-tabs-tab {
      margin: 0 0 0 18px;
    }
  }

}


.ant-select-item-option-content,
.ant-select-selection-placeholder,
.ant-input::placeholder,
.ant-picker-input > input::placeholder,
.ant-input,
.ant-form-item-label>label,
.ant-form-item-control-input-content,
.ant-menu-item a,
.ant-menu-submenu-title,
.ant-btn,
.ant-table,
.ant-form label,
.ant-picker-input,
.ant-tabs-tab-btn,
body,
.ant-select-selection-item {
  font-size: 12px !important;
}

.ant-table-pagination.ant-pagination,

.ant-pagination {
  display: flex;
  justify-content: center;
}
.ant-pagination-item-active {
  border-color: #0070CC;
}

/* 弹框按钮样式 */
// .ant-modal-body {
//   .ant-btn {
//     width: 64px;
//     height: 28px;
//     line-height: 1px;
//     font-size: 12px;
//     color: #5C6173;
//     border-radius: 2px;
//   }
//   .ant-btn-primary {
//     background: #0070CC!important;
//     border-color: #0070CC!important;
//     color: rgba(255, 255, 255, 0.85) !important;
//   }
// }

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: ''!important;
  background: url("xing.png");
  background-size: 12px 12px;
  width:12px;
  height:12px;
}

// 穿梭框最大长度固定
// 去掉这个样式穿梭框的长度会根据里面内容的多少变的更大
.ant-transfer-list {
  max-width: 500px !important;
}